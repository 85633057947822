<template>
  <v-form @submit.prevent="createPassword">
    <v-text-field
      color="primary"
      :type="showPassword ? 'text' : 'password'"
      name="password"
      v-model="password"
      :error="$v.password.$error"
      :label="$t('password.title_and_rules')"
      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
      autocomplete="new-password"
      outlined
      loading
      @click:append="showPassword = !showPassword"
    >
      <template v-slot:progress>
        <v-progress-linear
          style="width: 98%; border-bottom-left-radius: 30px; border-bottom-right-radius: 30px"
          class="mx-1"
          :value="passwordScore.value"
          :color="passwordScore.color"
          absolute
          height="7"
        ></v-progress-linear>
      </template>
    </v-text-field>

    <v-expand-transition>
      <ErrorMessage v-if="errorMessage !== null">
        {{ errorMessage }}
      </ErrorMessage>
    </v-expand-transition>

    <v-btn type="submit" color="primary" x-large block :loading="isSending" :disabled="password === ''">
      {{ $t('password.edit') }}
    </v-btn>
  </v-form>
</template>

<script>
import { minLength, required } from 'vuelidate/lib/validators'
import { computePasswordScore } from '@/common/reusable/security'
import { resetPassword } from '@access/services/accessService'
import ErrorMessage from '@/components/ErrorMessage'

export default {
  name: 'CreatePasswordForm',
  components: { ErrorMessage },
  data() {
    return {
      password: '',
      showPassword: false,
      errorMessage: null,
      sending: false,
    }
  },
  computed: {
    passwordScore() {
      return computePasswordScore({ password: this.password })
    },
    isReset() {
      return this.reset
    },
    isSending() {
      return this.sending
    },
  },
  validations() {
    const containsUppercase = (value) => /[A-Z]/.test(value);
    const containsSpecialChar = (value) => /[0-9!@#$%^&*()_+[\]{}|;:'",.<>?/]/.test(value);

    return {
      password: {
        required,
        minLength: minLength(8),
        containsUppercase,
        containsSpecialChar
      },
    }
  },
  methods: {
    createPassword() {
      this.sending = true

      this.$v.password.$touch()
      if (this.$v.password.$error) {
        if(!this.$v.password.required) {
          this.$notification.error(this.$t('password.required'))
        }
        if(!this.$v.password.minLength) {
          this.$notification.error(this.$t('password.min_length_error'))
        }
        if(!this.$v.password.containsUppercase) {
          this.$notification.error(this.$t('password.uppercase_error'))
        }
        if(!this.$v.password.containsSpecialChar) {
          this.$notification.error(this.$t('password.special_char_error'))
        }

        this.sending = false

        return
      }

      const passwordChangeToken = this.$route.params.token

      if (!passwordChangeToken) {
        return
      }

      resetPassword({
        credentials: {
          password: this.password,
          hash: passwordChangeToken,
        },
      })
        .then(() => {
          this.errorMessage = ''

          return this.$router.push({
            name: 'passwordResetDone',
            params: {
              token: passwordChangeToken,
            },
          })
        })
        .catch(() => {
          this.errorMessage = this.$t('login.invalid_password_reset')
        })
        .finally(() => {
          this.sending = false
        })
    },
  },
}
</script>

<style lang="scss" scoped></style>
