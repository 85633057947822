<template>
  <v-toolbar class="mt-3" dense flat>
    <v-btn v-if="isSecondStep" color="#6d7175" class="font-weight-regular" text @click="$emit('on-back-button-click')">
      <v-icon left>mdi-arrow-left</v-icon>
      {{ $t('general.back_to_first_step') }}
    </v-btn>
    <v-spacer></v-spacer>
    <LanguageToggle v-if="!isSecondStep" :locale="currentLocale" @onLocaleChanged="changeLocale($event)" />
  </v-toolbar>
</template>

<script>
import LanguageToggle from '@/common/components/LanguageToggle'
import { changeLocaleWithoutSave, getCurrentLocale } from '@/app/services/localeService'

export default {
  name: 'LanguageToolbar',
  components: { LanguageToggle },
  props: {
    isSecondStep: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    currentLocale() {
      return getCurrentLocale(this)
    },
  },
  methods: {
    changeLocale(locale) {
      localStorage.setItem('sign_app_locale', locale)
      const isChangedLocale = changeLocaleWithoutSave(this, locale)

      if (isChangedLocale) {
        this.actualLocale = locale
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
